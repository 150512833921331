import React from "react";
import { Link } from "gatsby";

const PostLink = ({ post }) => (
    <div className="card flex flex-col rounded-lg overflow-hidden">
        <div className="flex-shrink-0">
            <Link to={post.frontmatter.path}>
                {!!post.frontmatter.thumbnail && (
                    <img
                        className="h-48 w-full object-cover"
                        src={post.frontmatter.thumbnail}
                        alt={post.frontmatter.title + "- Featured Shot"}
                    />
                )}
            </Link>
        </div>
        <div className="flex-1 bg-gray-100 bg-opacity-50 p-6 flex flex-col justify-between">
            <div className="flex-1">
                <p className="text-sm font-medium text-cyan-600">
                    <Link to={post.frontmatter.path} className="hover:underline">
                        Article
                    </Link>
                </p>
                <Link to={post.frontmatter.path} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                        {post.frontmatter.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                        {post.excerpt}
                    </p>
                </Link>
            </div>
            <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                    <Link to={post.frontmatter.path}>
                        <img className="h-10 w-10 rounded-full" src="../assets/tim.png" alt="Tim Benstead" />
                    </Link>
                </div>
                <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                        <Link to={post.frontmatter.path} className="hover:underline">
                            Tim Benstead
                        </Link>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                        <time dateTime="2020-03-16">
                            {post.frontmatter.date}
                        </time>
                        {/* <span aria-hidden="true">
                            &middot;
                        </span>
                        <span>
                            6 min read
                        </span> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default PostLink;
